// extracted by mini-css-extract-plugin
export var designDevelopmentExpertsSection = "p_bJ";
export var designDevelopmentServices = "p_bG";
export var designDifferenceBetweenSection = "p_bH";
export var designFeaturedCaseSection = "p_bR";
export var designFullVideoSection = "p_bL";
export var designInsightsSection = "p_bS";
export var designPhaseSection = "p_bN";
export var designPrimeSection = "p_bF";
export var designProcessSection = "p_bM";
export var designProjLogoSection = "p_bP";
export var designTestimonialPosSection = "p_bK";
export var domainExpertiseDesign = "p_bQ";